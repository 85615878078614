import { Helmet } from "react-helmet";

const DocumentTitle = (props: { pageTitle?: string }) => {
  const pageTitle = `${props.pageTitle || ""}${props.pageTitle ? " | " : ""}Lantern SecretShare`;
  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default DocumentTitle;
